import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import SignupHero from '../components/heros/signup-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import GenericPage from '../components/containers/generic-page'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class ContactPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Navigation />

        <GenericPage>
          <h1>Contact me</h1>
          <p>The best way of contacting me is to email me directly at: <a href="mailto:callum@uiuxtips.com">callum@uiuxtips.com</a>. I'll make this easier by putting a form here some day.</p>
        </GenericPage>

        <div className="">
          <EmailCtaSection />
        </div>
      </Layout>
    )
  }
}

export default ContactPage

export const ContactQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const SectionHeadline = styled.h2 `
  margin-bottom: 48px;
`

const TipCount = styled.div `
  color: #7A89FF;
  display: inline-block;
`

const YouTubeVideo = styled.iframe `
  border-radius: 8px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`

const FeatureList = styled.ul `
  display: flex;
  flex-wrap: wrap;
`